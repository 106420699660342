@font-face {
	font-family: openSans;
	font-style: normal;
	src: url("fonts/OpenSans-Regular.ttf");
}

a {
	text-decoration: inherit;
	color: inherit;
}

body {
	background-color: #e0e0e0;
	font-family: 'openSans' !important;
}

.clearfix {
	overflow: auto;
}

.container {
	display: flex;
	justify-content: center;
}

.app-container {
	background-color: inherit;
	text-align: center;
	margin-top: 100px;
}

.grid-list-component {
	background-color: #fff;
}

.grid-list-component .header-content {
	width: 100%;
}

.grid-list-component .header-content .search {
	display: flex;
	margin: 10px;
	justify-content: flex-start;
}

.grid-list-component .header-content .title-bar {
	font-size: 28px;
	margin: 5px;
	align-items: center;
}

.grid-list-component .container-grid-list {
	overflow: hidden;
	width: 100%;
	padding: 2px;
}

.grid-list-component .container-grid-list .item-grid-list {
	cursor: pointer;
}

.grid-list-component .container-grid-list .item-grid-list:hover {
	background-color: #bebebe;
}

.grid-list-component .container-grid-list .item-grid-list .img-grid-list {
	min-height: 200px;
}

.app-bar {
	background-color: #fff !important;
	width: 100%;
	flex-grow: 1;
}

.app-bar .tabs {
	display: flex;
	justify-content: center;
}

.app-bar .tabs .text-tab {
	color: #424242;
}

.app-bar-management {
	margin-top: 80px;
}

.app-bar-management div {
	display: flex;
	justify-content: center;
}

.margin-top {
	margin-top: 150px;
}

.img-logo {
	padding: 10px;
	max-width: 330px;
	flex: 1;
}

.card-component {
	margin: 20px;
	width: 100;
	float: left;
}

.card-component .container-card {
	width: 200px;
	padding: 5px;
}

.card-component .container-card.card-selected {
	border: 1px solid #c00;
}

.main-content {
	display: flex;
	justify-content: center;
	width: 100%;
}

.list-brands {
	position: relative;
	height: 500px;
	overflow-y: auto;
	width: 25%;
}

.mark-content {
	width: 90%;
	height: 100%;
	overflow-y: auto;
	background: #fff;
	padding-left: 5px;
	padding-right: 5px;
}

.general-content {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background: #fff;
	padding: 10px 5px 10px 5px;
}

.general-content .btn-actions-general {
	width: 100%;
}

.area-drop {
	cursor: pointer;
	border: 2px dashed #666;
	width: 200px;
	height: 100px;
	padding: 10px;
}

.tab-container {
	padding: 10px;
	width: 100%;
	min-height: 300px;
	display: flex;
	justify-content: center;
}

.tab-container .paper-container {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	min-width: 70%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
}

.tab-container .paper-container .item-tab-container {
	float: left;
	width: 100%;
}

.tab-container .paper-container .item-tab-container .item-tab-list {
	max-height: 300px;
	overflow-y: auto;
}

.tab-container .paper-container .item-tab-container .expand-panel {
	margin: 10px;
}

.expand-panel-details {
	display: flex;
	justify-content: center;
}

.expand-panel-details .list{
	width: 100%;
}


.btns-bottom {
	margin-top: 20px;
}

.btn-extra {
	margin: 5px;
}

.btn-next {
	width: 100%;
	margin: 5px;
}

.btn-margin-sides {
	margin-left: 10px;
	margin-right: 10px;
}

.btn-update-data {
	margin-top: 200px !important;
}

.padding-left-0 {
	padding-left: 0px !important;
}

.management-vehicles, .list-items-maintenance {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.management-vehicles div, .list-items-maintenance div {
	flex-grow: 1;
	overflow-y: auto;
}

.management-vehicles > div:not(:first-child) {
	border-left: 1px solid rgba(127,140,141,0.3);
}

.management-vehicles > div > div {
	margin-top: 10px;
}

.list-items-maintenance {
	display: flex;
	justify-content: center;
	max-width: 100%;
	height: 100%;
}

.list-items-maintenance div ul {
	height: 200px;
	overflow-y: auto;
}

.btn-save-item-maintenance {
	margin: 30px !important;
}

.list-items-maintenance > div {
	flex: 1;
	margin-top: 20px;
}

.list-items-maintenance > div:not(:first-child) {
	border-left: 1px solid rgba(127,140,141,0.3);
}

.paper-content {
	padding: 10px;
	height: 100%;
}

.paper-content .field {
	margin: 5px;
}

.autocomplete-container .autocomplete-form-control {
	width: 100%;
}

.autocomplete-container .autocomplete-label {
	margin: 0;
}

.autocomplete-container .autocomplete-paper {
	max-height: 200px;
	overflow-y: auto;
}

.full-height-width {
	flex-basis: 80px;
	height: 100% !important;
	margin: 0px auto !important;
}

.circle-progress {
	padding: 0 !important;
	height: 41px !important;
	width: 60px !important;
}

.circle-progress svg {
	position: relative;
	top: 10px;
	width: 50% !important;
	height: 60% !important;
}

.form-parts div {
	margin-left: 5px;
	margin-right: 5px;
}

.list-parts {
	max-height: 350px;
	overflow-y: auto;
}

.grid-list-models {
	margin: 20px !important;
	display: flex;
	justify-content: center;
}

.grid-list-models li {
	margin: 10px;
	cursor: pointer;
}

.grid-list-models li:hover {
	background-color: rgba(255,0,0,0.8);
}

.autocomplete-operations {
	margin-left: 10%;
	margin-right: 10%;
}

.form-add-maintenance {
	display: flex;
	margin: 10px;
}

.form-add-maintenance > div, .form-add-maintenance > label {
	padding: 10px;
}

.form-add-part-to-maintenance {
	display: flex;
	justify-content: center;
	margin-left: 10%;
	margin-right: 10%;
}

.form-add-part-to-maintenance > div {
	display: flex;
	width: 100%;
}

.form-add-part-to-maintenance > div.field-quantity {
	max-width: 15%;
	max-height: 10%;
	display: inline-table;
}

.form-add-part-to-maintenance > div.autocomplete {
	margin-left: 20px;
}

.text-align-left {
	text-align: left;
}

.upload-image-vehicles {
	display: flex;
	align-items: center;
	min-height: 200px;
	flex-direction: column;
}

.head-banner {
	display: flex;
	justify-content: center;
	background: rgba(255,0,0,0.8);
	color: #fff;
}

.head-banner > div, .head-banner > img {
	margin-left: 10px;
	margin-right: 10px;
}

.head-banner > div {
	display: flex;
	align-items: center;
}

.items-works {
	width: 100%;
}

.items-works-total {
	text-align: right;
	margin-top: 10px;
	padding: 0 !important;
}

.items-works-total h3 span {
	font-weight: 800;
}

.items-works .item-works {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.items-works .item-works div {
	padding: 5px;
}

.items-works .item-works div.head {
	background: #808080;
	color: #fff;
}

.items-works .item-works div.foot {
	display: flex;
	justify-content: flex-end;
}

.items-works .item-works div ul li {
	padding: 0 !important;
}

.column-header > * {
	text-align: center !important;
	font-size: 14px;
	color: #000;
}

.row-item td:first-child {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column-reverse;
}

.row-item td:first-child span {
	text-align: center !important;
}

.input-add-operation {
	width: 25% !important;
}

.button-mt10 {
	margin-top: 10px !important;
}

.menu-item {
	min-height: 45px;
}

.list-item-text {
	text-align: center;
}

.text-sm {
  font-size: 12px;
}

.text-bold {
	font-weight: 700;
}

.color-red {
	color: #c00;
}